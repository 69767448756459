// styles
import '../styles.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// third party components
import moment from 'moment';
import { useCallback, useState, MutableRefObject, forwardRef, useImperativeHandle, useEffect } from 'react';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { TypeComputedProps, TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { isBoolean, isEmpty, isNil, isObject, pick } from 'lodash';
// data
import { useGetLogEntriesWithCounts } from 'src/hooks/logEntries/useGetLogEntriesWithCounts';
import { LogEntry, LogEntryFilterInput, LogEntrySortInput, SortEnumType } from 'src/generated/dotnet.graphql';
// YMS components
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import GridRowIcon from 'src/components/UI/LicensedReactDataGrid/components/GridRowIcon';
import LogEntryPDF from '../../../components/UI/PDF/LogEntryPDF';
// utils
import { exportCSV, exportExcel, getPlainTextFromHtml } from '../../../utils';
import { useGetLazyLogEntries } from 'src/hooks/logEntries/useGetLazyLogEntries';
import { utcToTimeZone } from 'src/utils/format-dates';
  
const filter = [
  {
    name: 'logDate',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'fldHtml',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'department',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'equipment.uniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldWorkList',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  // {
  //   name: 'fldEnteredBy',
  //   operator: 'contains',
  //   type: 'string',
  //   value: '',
  // },
  {
    name: 'fldPerformedBy',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'logDate': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateStart = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          logDateStart: dateStart,
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateEnd = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          logDateEnd: dateEnd,
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startDate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const endDate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          logDateStart: startDate,
          logDateEnd: endDate,
        };
      }
      return {};
    }
    case 'equipment.uniqueName': {
      return {
        equipment: value,
      };
    }
    case 'fldHtml':
    case 'department':
    case 'fldWorkList':
    case 'fldPerformedBy':
    case 'fldEnteredBy': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

const transformData = (data: any[]): Record<string, LogEntry> => {
  return data.reduce((acc, { pkey, ...rest }) => {
    acc[pkey] = rest;
    return acc;
  }, {});
}

type LogEntryGridProps = {
  darken: boolean;
  onSelect: (logEntry: LogEntry) => void;
  skip: number;
  setSkip: (skip: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
  filterValue: any;
  setFilterValue: (filterValue: any) => void;
  sortValue: any;
  setSortValue: (sortValue: any) => void;
  rowSelected: Record<string, LogEntry>;
  setRowSelected: (rowSelected: Record<string, LogEntry>) => void;
};

interface LogEntryGridRef {
  handleExport: (type: string) => void;
  handleGeneratePDF: () => void;
}

interface ExportDialog {
  visible: boolean;
  type: string;
  title: string;
}
  
const LogEntryGrid = forwardRef<LogEntryGridRef, LogEntryGridProps>(({ 
  onSelect, 
  skip,
  setSkip,
  limit,
  setLimit,
  filterValue,
  setFilterValue,
  sortValue,
  setSortValue,
  rowSelected,
  setRowSelected
}: LogEntryGridProps, ref) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [gridRef, setGridRef] = useState<any>(null);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
  const { getLazyLogEntries } = useGetLazyLogEntries({ filterInput: filterValue, order: sortValue });
  const { data, totalCount, loading, loadData } = useGetLogEntriesWithCounts({ skip: skip, take: limit, filterInput: filterValue, order: sortValue });
  const [exportDialog, setExportDialog] = useState<ExportDialog | null>(null);

  useImperativeHandle(ref, () => ({
    handleExport: (type) => {
      handleDataExport(type);
    },
    handleGeneratePDF: () => {
      return handleGeneratePDF();
    },
  }));

  // const mergeMasterSearchFilters = (masterSearchInput: MasterSearchInputValue, filterValue: LogEntryFilterInput): LogEntryFilterInput => {
  //   // Create a copy of filterValue to avoid mutating state directly
  //   const newFilters: LogEntryFilterInput = { ...filterValue };
  //   newFilters.searchText = masterSearchInput.searchText;
  //   if (masterSearchInput.subFilterValue) {
  //     Object.keys(masterSearchInput.subFilterValue).forEach((key) => {
  //       const value = masterSearchInput.subFilterValue?.[key];
  //       (newFilters as any)[key] = value;
  //     });
  //   }
  //   return newFilters;
  // };

  // useEffect(() => {
  //   if (masterSearchInput) {
  //     const combinedFilters = mergeMasterSearchFilters(masterSearchInput, filterValue);
  //     // Check if the combined filters are different from the current filterValue
  //     if (JSON.stringify(combinedFilters) !== JSON.stringify(filterValue)) {
  //       setFilterValue(combinedFilters);
  //     }
  //   }
  // }, [masterSearchInput]);

  useEffect(() => {
    loadData(skip, limit, filterValue, sortValue)
  }, [skip, limit, filterValue, sortValue])
    
  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: LogEntrySortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }
    
  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;
  
    const filterPayload: Record<string, any> = {};
  
    await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return;
  
        const selector = await getSelectorByFilterName(v.name, v.value);
        if (selector) {
          Object.keys(selector).forEach((key) => {
            filterPayload[key] = selector[key];
          });
        }
      })
    );
    setFilterValue(filterPayload as LogEntryFilterInput);
  };

  const onGroupByChange = (groupBy: string[]) => {
    if (isEmpty(groupBy)) return;

    let sortPayload: LogEntrySortInput[] = [];

    groupBy.forEach(sortField => {
      const [field, subField] = sortField.split('.');

      if (subField) {
          // Handle nested objects
          sortPayload.push({
              [field]: {
                  [subField]: SortEnumType.Asc,
              },
          });
      } else {
          // Handle non-nested objects
          sortPayload.push({
              [field]: SortEnumType.Asc,
          });
      }
    });
    setSortValue(sortPayload);
    gridRef.current.collapseAllGroups();
  }

  const onSelectionChange = useCallback(({ selected }) => {
    if (isBoolean(selected) && selected) {
      const transformedData = transformData(data);
      setRowSelected(transformedData)
    } else {
      setRowSelected(selected);
    }
  }, [data, rowSelected]);

  const onRowClick = useCallback(
    ({ data }) => {
      if (!data?.__group) {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data);
        }
      }
    },
    [rowSelected]
  );

  const handleGeneratePDF = async () => {
    const queryResult = await getLazyLogEntries({ variables: { filterInput: filterValue, order: sortValue } });
    const reportData = queryResult?.data?.logEntries?.items || [];
    const reportCurrentCount = queryResult?.data?.logEntries?.items?.length || 0;
    return LogEntryPDF(reportData, reportCurrentCount);
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  const renderGroupTitle = (value: any, { data }: any) => {
     const columns = data.fieldPath.map((col: string) =>
      col === data.name ? col.toUpperCase() : col
    );
    let path = columns && columns.length && columns.join('>');
      let dataValue = data.value;
    if (path.includes('logDate')) {
      dataValue = moment(data.value).format('DD-MMM-YYYY');
    }
    return data.value === 'null' ? `No ${path} Assigned` : dataValue;
  };

  const CollapseButton = () => {
    if (groupCollapsedStatus) {
      setGroupCollapsedStatus(false);
      return gridRef.current.collapseAllGroups();
    }
    if (!groupCollapsedStatus) {
      setGroupCollapsedStatus(true);
      return gridRef.current.expandAllGroups();
    }
  };
  
  // Find if any filters applied to grid
  const areFiltersActive = gridRef?.current.computedFilterValue.some((f: { value: any; }) => !isEmpty(f.value));

  const exportData = (type: string, withFilters: boolean) => {
    setExportDialog(null);
    switch (type) {
      case 'CSV':
        return onExportToCSV(withFilters);
      case 'xlsx':
        return onExportToExcel(withFilters);
      default:
    }
  };

  const getRows = async (issue: any) => {
    const rows =  issue.map((data: any) =>{
      return {
        ...pick(data, [
          'fldHtml',
          'department',
          'fldWorkList',
          'fldPerformedBy',
          'fldEnteredBy',
        ]),
        logDate: moment(data.logDate).format('DD-MMM-YYYY'),
        'equipment.uniqueName': data.equipment?.uniqueName
      };
    }   
    );
    return rows
  };

  const onExportToExcel = async (withFilters: boolean) => {
    const queryResult = await getLazyLogEntries({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.logEntries || [];
    const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
      header: c.header,
      key: c.id,
    }));
    const columns = columnsData.filter((item: { header: any; }) => {
      return item.header && typeof item.header === 'string';
    });
    const rows = await getRows(data)
    return exportExcel(columns, rows);
  };

  const onExportToCSV = async (withFilters: boolean) => {
    const columns = gridRef.current.visibleColumns;
    const queryResult = await getLazyLogEntries({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.logEntries || [];    
    const rows = await getRows(data)
    return exportCSV(columns, rows);
  };

  const handleDataExport = (type: string) => {
    if (areFiltersActive) {
      setExportDialog({
        visible: true,
        type,
        title: type === 'CSV' ? 'CSV' : 'Excel',
      });

      return;
    }
    exportData(type, false);
  };

  const columns = [
    {
      id: 'icons',
      header: 'Icons',
      defaultWidth: 140,
      render: ({ data }: any) => (
        <GridRowIcon 
          hasAttachments={data.documentsCount > 0} 
          hasPhotos={data.photosCount > 0} 
          hasSpares={data.sparesUsedCount > 0} 
          hasComments={data.commentsCount > 0} 
          isCritical={data.fldSms} 
        />
      ),
      onRender: (cellProps: any) => {
        cellProps.style.borderLeft = '#e4e3e2 3px solid';
      },
      sortable: false
    },
    {
      name: 'logDate',
      header: 'Date',
      defaultFlex: isDesktop ? 1.5 : undefined,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      dateFormat: 'DD-MMM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (_props: any, { index }: any) => {
        return { placeholder: index == 1 ? 'End...' : 'Start...' };
      },
      render: ({ value, cellProps: { dateFormat } }: any) => {
        if (!isNil(value)) {
          const UTCLogDate = utcToTimeZone(new Date(value));
          const formatedDate = moment(UTCLogDate).format(dateFormat);
          return formatedDate;
        }
      },
    },
    {
      name: 'year',
      header: 'Log Date Year',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'month',
      header: 'Log Date Month',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'equipment.uniqueName',
      header: 'Equipment',
      defaultFlex: isDesktop ? 1 : undefined,
      render: ({ data }: any) => <span>{data?.equipment?.uniqueName}</span>,
    },
    {
      name: 'fldWorkList',
      header: 'Work List',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'fldHtml',
      header: 'Log Entry',
      defaultFlex: isDesktop ? 1 : undefined,
      render: ({ data }: any) => {
        const plainTextDescription = getPlainTextFromHtml(data.fldHtml);
        return (<span>{plainTextDescription}</span>);
      },
    },
    {
      name: 'fldPerformedBy',
      header: 'Performed By',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    // {
    //   name: 'fldEnteredBy',
    //   header: 'Entered By',
    //   defaultFlex: isDesktop ? 1 : undefined,
    // },
    {
      name: 'department',
      header: 'Department',
      defaultFlex: isDesktop ? 1 : undefined,
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow">
      <div className="flex flex-row items-center justify-end">
        {!isEmpty(gridRef?.current.computedGroupBy) &&
          (groupCollapsedStatus ? (
            <div>
              <Tooltip title="Collapse All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip title="Expand All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            </div>
          ))}
  
        {/* {groupByFlag ? */}
        {/* <Tooltip title="Remove Group by Year/month">
          <IconButton onClick={() => setGroupBy([])}>
            <GroupRemoveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Group by Year/month of log Date">
          <IconButton onClick={() => onGroupByChange(["logDate"])}>
            <GroupAddIcon />
          </IconButton>
        </Tooltip> */}
      </div>

      <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={columns}
        pagination='remote'
        idProperty='pkey'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        defaultGroupBy={[]}
        onGroupByChange={onGroupByChange}
        collapsedGroups={collapsedGroups}
        onGroupCollapseChange={setCollapsedGroups}
        renderGroupTitle={renderGroupTitle}
        sourceRoot={true}
      />   

      <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
        <DialogTitle>
          {`Export data to ${exportDialog?.title}`}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You have filters applied. Would you like to export with current
            filters?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() => exportDialog && exportData(exportDialog?.type, false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => exportDialog && exportData(exportDialog?.type, true)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default LogEntryGrid;
