import './styles.scss';
import { FC, useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { Alert, AlertColor, MenuItem, Select, Snackbar } from '@mui/material';
import CommentInput from './components/CommentInput';
import Comment from './components/Comment/index';
import { useAuth } from '../../contexts/auth';
import { AddCommentInput, CommentSortInput, Comment as CommentType, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import { useGetComments } from 'src/hooks/comments/useGetCommentsForRecord';
import { useAddComment } from 'src/hooks/comments/useAddCommentsForRecord';
import { isNotNil } from 'src/utils';
import { SEVERITY } from 'src/consts';

interface InjectedProps {
  recordId: string;
  recordType: RecordType;
  recordTypeName: string;
  setCommentsCount: (value: number) => void;
  readOnly?: boolean;
  addCommentOnly?: boolean;
}

const orderOptions = [
  { key: SortEnumType.Desc, label: 'Newest First' },
  { key: SortEnumType.Asc, label: 'Oldest First' },
];

const Comments: FC<InjectedProps> = ({
  recordId,
  recordType,
  recordTypeName,
  setCommentsCount,
  readOnly = false,
  addCommentOnly = false,
}) => {
  const { user } = useAuth();
  const [sortValue, setSortValue] = useState<CommentSortInput>({ createdAt: SortEnumType.Desc });
  const { commentsForRecord, commentsForRecordCount, commentsForRecordLoading } = useGetComments(recordId, recordType, sortValue);
  const { addComment } = useAddComment(recordId, recordType, recordTypeName, false);
  const [comments, setComments] = useState<CommentType[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  useEffect(() => {
    if (isNotNil(recordId) && !commentsForRecordLoading) {
      setComments(commentsForRecord);
      setCommentsCount(commentsForRecordCount);
      setLoading(commentsForRecordLoading);
    }
  }, [recordId, commentsForRecordLoading, commentsForRecordCount, sortValue]);

  const handleSortChange = (event: any) => {
    const sortField = event.target.name;
    const sortOrder = event.target.value;
    setSortValue({
      ...sortValue,
      [sortField]: sortOrder,
    });
  };

  const onSubmit = async (content: string, id?: string) => {
    if (!user) return;

    const payload: AddCommentInput = {
      recordId,
      recordType,
      content,
    };
  
    const { responseData, responseMessage } = await addComment(payload);

    if (responseData) {
      setComments([...comments, responseData]);
    }
 
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const renderCommentList = () => comments.map((comment) => {
    return (
      <Comment
        key={comment.id} 
        comment={comment} 
        recordId={recordId}
        recordType={recordType}
        recordTypeName={recordTypeName}
        setComments={setComments}
        isReply={false}
        readOnly={readOnly || addCommentOnly} 
        setSnackbar={(message, severity) => setSnackbar({ message, severity })} 
      />
    )
  });

  return (
    <div className="comments h-full pb-19">
      <div className="flex flex-row items-center pb-2">
        <label htmlFor="orderBy" className="text-sm font-bold block pl-12 pr-2">
          Order By
        </label>
        <Select
          id="orderBy"
          size="small"
          name='createdAt'
          value={sortValue.createdAt}
          onChange={handleSortChange}
        >
          {orderOptions.map((o) => (
            <MenuItem key={o.key} value={o.key}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </div>
          
      <CommentInput 
        className="pt-2 pb-2" 
        onSubmit={onSubmit} 
        readOnly={readOnly} 
      />

      {renderCommentList()}

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Comments;
