import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { isNil, size } from 'lodash';

// MUI Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  FormGroup,
  IconButton,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {CircularProgress} from "@mui/material";

// TDI Componenents
import Checkbox from 'src/components/UI/Forms/Checkbox';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import Input from 'src/components/UI/Forms/Input';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import LocationDropdownOld from '../../../Dropdowns/LocationDropdown/indexOld';
import CategoryDropdownOld from '../../../Dropdowns/CategoryDropdown/indexOld';

// Utilities
import { handleCharLimitWarning, isNotNil, toJSON } from '../../../../utils';

// Data
import { getDatabase } from '../../../../rxdb';
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import { Equipment } from '../../../../generated/graphql';
import { useAppState } from 'src/contexts/app-state';
import SelectEquipmentDialog from 'src/pages/RunningLogsGroupsPage/component/SelectEquipmentDialog';
import { getIcon } from 'src/components/UI/SidebarMenu/utils';
import { CHAR_LIMIT, ICON } from 'src/consts';
import { isValidDateFormat } from 'src/utils/format-dates';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';

interface EqItemSummaryProps {
  form: Equipment;
  initialValue: EquipmentDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  setValue: any;
  disableEdit? : boolean;
}

const EqSummaryForm: FC<EqItemSummaryProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  getValues,
  setValue,
  disableEdit=false,
}) => {
  const { settingsPersonal } = useAppState();
  const [reportingType, setReportingType] = useState<TblDdListDefaultsDocument[]>([]);
  const [departments, setDepartments] = useState<TblDdListDefaultsDocument[]>([]);
  const [isInheriting, setIsInheriting] = useState(isNotNil(initialValue.fldParent));
  const [showEquipmentDialog, setShowEquipmentDialog] = useState(false);
  const isMonitorHoursChecked = watch("fldCountHours");
  const [parentUniqueName, setParentUniqueName] = useState<string>();
  const { EQUIPMENT } = CHAR_LIMIT;

  const init = async () => {
    const db = await getDatabase();

    const parent = await db.equipment.findOne({
      selector:{
        EqKey: initialValue.fldParent
      }
    }).exec()
    setParentUniqueName(parent?.UniqueName)

    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } } }).$.subscribe((defaults: any) => {
      const reportingType = defaults.filter((d: any) => d.fldListName === 'Reporting Type' && d.fldMember !== "");
      const departments = defaults.filter((d: any) => d.fldListName === 'Department' && d.fldMember !== "");
      setDepartments(toJSON(departments));
      setReportingType(toJSON(reportingType));
    });
  };

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
  ) => {
    const sortedData = items?.sort((a: any, b: any) => a.fldMember.localeCompare(b.fldMember));
    return (
      <Autocomplete
        defaultPayload={{
          fldListName,
          fldT4Reqd: true,
        }}
        displayExpr="fldMember"
        keyExpr="PKey"
        onChange={onChange}
        freeSolo={freeSolo}
        options={sortedData}
        label={fldListName}
        control={control}
        name={fieldName}
        disabled={fldListName === "Department" ? disableEdit: false}
        collection="tblddlistdefaults" // Use this in order to create new items.
      />
    );
  };

  const handleSelectEquipment = async (eq: EquipmentDocument) => {
    setValue('fldCountHours', true, {shouldDirty: true});
    setValue('fldParent', eq.EqKey, {shouldDirty: true});
    setValue('Hours', eq.Hours), {shouldDirty: true};
    setParentUniqueName(eq.UniqueName);
    setIsInheriting(true);
    setShowEquipmentDialog(false);
  }

  const handleUninherit = () => {
    setValue('fldParent', null, {shouldDirty: true});
    setIsInheriting(false);
  }

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">

            <FormGroup row className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="w-40">
                <Checkbox
                  control={control}
                  name="fldCountHours"
                  label="Monitor Hours"
                />
              </div>
              {!isInheriting && (
                <div className="ml-5" style={{ marginTop: '6px' }}>
                  {!isInheriting ? (
                    <Button onClick={() => setShowEquipmentDialog(true)} disabled={disableEdit} >
                      Inherit Hours
                    </Button>
                  ) : (
                    <Button onClick={handleUninherit} disabled={disableEdit} >
                      Uninherit Hours
                    </Button>
                  )}
                </div>
                )}
            </FormGroup>

            { isMonitorHoursChecked && (
              <div className="flex items-center mt-1">
                <div className="w-40">
                  <Input
                    name="Hours"
                    inputProps={{
                      label: 'Hours',
                      type: 'number',
                      inputProps: { min: 0, style: { textAlign: 'end' } },
                    }}
                    control={control}
                    rules={{
                      min: 0,
                    }}
                    disabled={isInheriting}
                  />
                </div>
                {isInheriting && (
                  <div className="flex items-center mt-1">
                    {parentUniqueName ? (
                      <span className="flex items-center mt-0 ml-8">
                        <InfoOutlinedIcon className='mr-1' color='disabled' />
                        <span style={{ fontSize: '14px', color: '#888' }}>{`Hours inherited from `}<strong>{parentUniqueName}</strong></span>
                      </span>
                    ) : (
                      <div className="flex items-center justify-center mt-0 ml-20">
                        <CircularProgress size={20} color='info' />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

          </div>

          {/* Category Hierarchial, collapsable Tree */}
          <div className="mt-3">
            <CategoryDropdownOld
              name="fldSRHKey"
              label="Category"
              defaultValue={initialValue.fldSRHKey}
              control={control}
              onChange={onChange}
              rules={{
                required: true,
              }}
              sourceForm="equipment"
              allDepts={settingsPersonal?.fldAllDepts > 0}
            />
          </div>

          <div className="mt-3">
            <LocationDropdownOld
              control={control}
              label="Location"
              name="fldLocHierarchy"
              onChange={onChange}
              sourceForm="equipment"
              allDepts={settingsPersonal?.fldAllDepts > 0}
              defaultValue={initialValue.fldLocHierarchy}
            />
          </div>

          <div className="mt-3">
            {renderListDefaultBox(departments, 'Department', 'Department', true)}
          </div>

          <div className="mt-3">
            {renderListDefaultBox(
              reportingType,
              'fldReportingType',
              'Reporting Type',
              true
            )}
          </div>

          <div className="mt-3">
            <FormGroup row>
              <Checkbox
                control={control}
                name="fldRestricted"
                label="Restricted to HoD"
              />
              <Checkbox
                control={control}
                name="fldSMS"
                label="Critical Equipment (SMS Required)"
              />
            </FormGroup>
          </div>

          <div className="my-4">
            <RichTextEditor control={control} name="Notes" onChange={onChange} />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="planning-status"
          id="planning-status"
        >
          Additional Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <CompaniesDropdownOld
              control={control}
              label="Supplier"
              name="Supplier"
              onChange={onChange}
            />
          </div>

          <div className="w-full flex flex-col mt-4">
            <FormGroup row>
              <DatePicker
                name="fldInService"
                control={control}
                label="Date In Service"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
              />

              <Box className="h-px w-2 self-center mx-2 bg-transparent" />

              <DatePicker
                name="fldExpireDate"
                control={control}
                label="Expiry Date"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
              />
            </FormGroup>
          </div>

          <div className="pt-4">
            <Input
              name="SerialNum"
              rules={{ maxLength: EQUIPMENT.SerialNum }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.SerialNum)}
              control={control}
              inputProps={{ label: 'Serial Number' }}
            />
          </div>

          <div className="pt-4">
            <Input
              name="ArrangementNum"
              rules={{ maxLength: EQUIPMENT.ArrangementNum }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ArrangementNum)}
              control={control}
              inputProps={{ label: 'Arrangement Number' }}
            />
          </div>

          <div className="pt-4">
            <Input
              name="Rating"
              rules={{ maxLength: EQUIPMENT.Rating }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.Rating)}
              control={control}
              inputProps={{ label: 'Rating' }}
            />
          </div>

          <div className="pt-4">
            <Input
              name="ArtNumber"
              rules={{ maxLength: EQUIPMENT.ArtNum }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ArtNum)}
              control={control}
              inputProps={{ label: 'Build Reference/Article Number' }}
            />
          </div>

          <div className="mt-4" />
        </AccordionDetails>
      </Accordion>

      {/* <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="assignment"
          id="assignment"
        >
          Assignment & Responsibility
        </AccordionSummary>

        <AccordionDetails>
          <div className="pt-4">
            <div>
              <ChipSelect
                name="AssignedTo"
                control={control}
                label="Assign to..."
                data={assignments}
              />
            </div>

            <div className="mt-4">
              {renderListDefaultBox(departments, 'Department', 'Department')}
            </div>

            <div className="mt-4">
              <CompaniesDropdownOld
                control={control}
                label="Company"
                onChange={onChange}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion> */}


      {showEquipmentDialog && (
        <SelectEquipmentDialog
          equipmentId="eqKey"
          onClose={() => setShowEquipmentDialog(false)}
          onSelect={handleSelectEquipment}
          isParent={true}
        />
      )}
    </div>
  );
};

export default EqSummaryForm;
