import './styles.css';
import { FC, useCallback, useState } from 'react';
import { ImageList, ImageListItem, ImageListItemBar, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { Edit, DeleteTwoTone, FitScreen } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { isEmpty, isNil } from 'lodash';
import { DOCUMENT_LIBRARY_TYPES, DOWNLOAD_URL } from 'src/consts';
import ImagePreviewPopup from './ImagePreviewPopup';
import WarningDialog from 'src/components/UI/WarningDialog';
import { Attachment, AttachmentType } from 'src/generated/dotnet.graphql';
import { downloadFile } from 'src/utils';

type AttachmentGridProps = {
  documents: Attachment[];
  recordId: string;
  attachmentType: AttachmentType;
  loading: boolean;
  onSelect: (data: Attachment) => void;
  onDelete: (data: Attachment) => void;
  readOnly?: boolean;
};

const AttachmentGrid: FC<AttachmentGridProps> = ({
  documents,
  recordId,
  attachmentType,
  loading,
  onSelect,
  onDelete,
  readOnly = false,
}) => {
  const [showPhotoPreview, setShowPhotoPreview] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const isMobile = useMediaQuery('(max-width:600px)');

  const onPhotoClick = (photo: any) => {
    setShowPhotoPreview(true);
    setImageSrc(`${DOWNLOAD_URL}/${photo.fldFileName}?w=164&h=164&fit=crop&auto=format`);
  };
  
  const onTitleClick = (photo: any) => {
    setShowPhotoPreview(true);
    setImageSrc(`${DOWNLOAD_URL}/${photo.fldFileName}?w=164&h=164&fit=crop&auto=format`);
  };

  const onFullScreenClick = (photo: any) => {
    const newTab = window.open(`${DOWNLOAD_URL}/${photo.fldFileName}`, '_blank');
    if (newTab) {
      newTab.focus(); // brings the new tab to focus if it's not null
    } else {
      console.error('Failed to open preview. Please check your popup blocker settings.');
    }
  };

  const handleOnClosePhoto = () => {
    setShowPhotoPreview(false);
  };
  
  const handleDeletePhoto = (data: any) => {
    setDeleteSelected(data);
    setIsDeleting(true);
  };

  const handleDeleteAttachment = (data: any) => {
    setDeleteSelected(data);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    onDelete && onDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const onDocumentView = (data: any) => {
    alert('To be implemented!')
  }

  const onDocumentDownload = (data: any) => {
    const downloadUrl = `${DOWNLOAD_URL}/${data.fldFileName}`;
    downloadFile(downloadUrl, data.fldFileName);
  }

  const onRowClick = useCallback(async ({ data }) => {
    if (!readOnly && recordId === data.recordId) {
      onSelect(data);
    }
  }, []);

  const attachmentGridColumns = [
    {
      name: "docTitle",
      header: "Document Title",
      flex: 1,
    },
    {
      name: 'fldLibType',
      header: 'Library',
      flex: 0.5,
      render:({data}:any) =>{
        if (!data.fldShowInCentral) {
          return '';
        }
        if (data.fldShowInCentral && isNil(data.fldLibType)) {
          return DOCUMENT_LIBRARY_TYPES.VESSEL_REFERENCE.DISPLAY_NAME
        }
        if (data.fldShowInCentral && data.fldLibType === DOCUMENT_LIBRARY_TYPES.DRAWINGS.TYPE) {
          return DOCUMENT_LIBRARY_TYPES.DRAWINGS.DISPLAY_NAME
        }
      }
    },
    {
      id: "actions",
      header: "Actions",
      flex: 0.5,
      render: ({ data }: any) => {
        return (
          <div className = "flex justify-center">
            <IconButton
              onClick={(event) => {
                onDocumentView(data);
                event.stopPropagation();
              }}
              size="small"
              aria-label="View item"
              className="mx-2"
            >
              <VisibilityIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              onClick={(event) => {
                onDocumentDownload(data);
                event.stopPropagation();
              }}
              size="small"
              aria-label="Download item"
              className="mx-2"
            >
              <DownloadIcon fontSize="inherit" />
            </IconButton>
          
            <IconButton
              onClick={(event) => {
                handleDeleteAttachment(data);
                event.stopPropagation();
              }}
              disabled={readOnly || (recordId !== data.recordId)}
              size="small"
              color="error"
              aria-label="Delete item"
              className="mx-2"
            >
              <DeleteTwoTone fontSize="inherit" />
            </IconButton>
          </div>
        )
      },
    },
  ];

  return (
    <>
      {attachmentType === AttachmentType.Document && (
         <div data-testid="data-grid" className="flex flex-col flex-grow attachment-grid">
         <LicensedReactDataGrid
            disableGroupByToolbar
            loading={loading} 
            defaultLimit={100}
            livePagination
            groupBy={['recordType']}
            rowHeight={40}
            idProperty="pKey"
            showHoverRows={false}
            columns={attachmentGridColumns}
            dataSource={documents}
            onRowClick={onRowClick}
         />
       </div>
      )}

      {/* Note: MUI File Upload is in progresshttps://mui.com/discover-more/roadmap/#heading-new-components */}
      {/* <FileUploader
        dropZone="#myID .dx-datagrid-rowsview"
        uploadMode="instantly"
        ref={fileUploaderRef}
        allowedFileExtensions={allowedFileExtensions}
        name="attachment"
        uploadUrl={`${import.meta.env.VITE_NODE_URL}/files/upload`}
        uploadHeaders={{ 'x-upload-path': 'images' }}
        visible={false}
        multiple={false}
        onUploaded={(e) => onUploaded(e)}
        onUploadError={onUploadError}
        onValueChanged={onSelectedFilesChanged}
      /> */}

      {attachmentType === AttachmentType.Photo && (
        <div className="pt-5">
          { isEmpty(documents) ? 
          (<div className="flex items-center justify-center w-full h-72">
            <span>No records available</span>
          </div>) :
          <ImageList sx={{ width: '100%' }} cols={isMobile ? 1 : 3}>
            {documents.map((photo) => (
              <ImageListItem key={photo.fldFileName} >
                <ImageListItemBar
                  title={photo.docTitle}
                  position="top"
                  onClick={() => onTitleClick(photo)}
                  actionIcon={(
                    <>
                      <Tooltip title="Preview">
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label="Preview"
                          onClick={(event) => {
                            event.stopPropagation();
                            onFullScreenClick(photo)
                          }}
                        >
                          <FitScreen />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Edit">
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label="Edit"
                          disabled={readOnly}
                          onClick={(event) => {
                            event.stopPropagation();
                            onSelect(photo);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label="Delete"
                          disabled={readOnly}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeletePhoto(photo);
                          }}
                        >
                          <DeleteTwoTone />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                />
                <img
                  src={`${DOWNLOAD_URL}/${photo.fldFileName}?w=${isMobile ? 300 : 164}&h=${isMobile ? 300 : 164}&fit=crop&auto=format`}
                  srcSet={`${DOWNLOAD_URL}/${photo.fldFileName}?w=${isMobile ? 600 : 328}&h=${isMobile ? 600 : 328}&fit=crop&auto=format&dpr=2 2x`}
                  alt={photo.docTitle || ''}
                  loading="lazy"
                  onClick={() => onPhotoClick(photo)}
                />
              </ImageListItem>
            ))}
          </ImageList>
          }
        </div>
      )}

      <ImagePreviewPopup
        popupVisible={showPhotoPreview}
        onClose={handleOnClosePhoto}
        imageSrc={imageSrc}
      />

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default AttachmentGrid;
