import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, isEmpty, isString, isArray, omit } from 'lodash';
import { Subscription } from 'rxjs';
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { useForm, useFormState } from 'react-hook-form';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Add, Close, DeleteTwoTone, FileCopy, Link, LinkOff, RestartAlt, Undo } from '@mui/icons-material';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { LogEntry, TblSchedMaintEq } from '../../../generated/graphql';
import EqMaintSummaryForm from './component/EqMaintSummaryForm';
import AttachmentTabOld from '../../../modules/Attachments/indexOld';
import { getDatabase } from '../../../rxdb';
import { CHAR_LIMIT, scheduleDeleteWarningMessage } from '../../../consts';
import CommentsOld from '../../../modules/Comments/indexOld';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import { validateForm } from './utils';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import EqSchedPMConfDialog from 'src/components/UI/EqSchedPMConfDialog';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { useAppState } from 'src/contexts/app-state';
import { useAuth } from 'src/contexts/auth';
import { copySpareUsedFromLogEntry, copySpareUsedFromWorkissue, equipmentHoursUpdate, filterNonNullStrings, handleCharLimitWarning, toJSON } from 'src/utils';
import HistoryTab from 'src/components/HistoryTab';
import IconTask from '../../../assets/icon-tasks-blue.svg';
import LogEntryDetailFormOld from '../logentryDE/LogEntryDetailFormOld';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import SnackbarCopyComponent from './SnackbarCopyComponent';
import { makeStyles } from '@material-ui/core';
import ProcedureDocument from './component/ProcedureDocument';
import AttachmentTabButton from "../../../modules/Attachments/component/AttachmentTabButton";
import useSnackbar from "../../../hooks/common/useSnackbar";
import {SchedMaintEQDocument} from "../../../rxdb/collections/SchedMaintEQ/schema";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { activateSchedules, deActivateSchedules } from 'src/pages/EquipmentSchedules/utils';
import { getRecurrencePerSchedMaint } from 'src/utils';
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import { getRecurrenceDate } from 'src/utils/Recurrence';
import { CustomDialog } from 'src/helpers/modals';
import { logger } from 'src/helpers/logger';

const useStyles: any = makeStyles((theme: any) => ({
  staticTooltipLabel : {
    minWidth: "max-content",
  }
}));

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: any;
  onCancel: () => void;
  onSave: (issue: SchedMaintEQDocument, isCreated: boolean, e?: any) => void;
  onDelete: (issue: any) => void;
  onTaskComplete?: (issue: any) => void;
  onUndo?: () => void;
  onCertificateInfoChange?: (isCreate: boolean, cb?: () => void) => void;
  onLogEntryInfoChange?: (
    isCreate: boolean,
    pendingWorkIssue?: TblSchedMaintEq
  ) => void;
  onRecurringTaskComplete?: () => void;
  onRecurringTaskCompleteCancel?: () => void;
  isCreated?: boolean;
  editFlag?: boolean;
  type?: string;
}

const EquipmentSchedulesDetailForm: FC<Props> = ({
    initialValue,
    onCancel,
    onSave,
    onDelete,
    onUndo,
    type,
    isCreated = false,
    editFlag = false,
    setFormIsDirty
  }) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      PKey: initialValue.PKey,
      fldSubject: initialValue.fldSubject || "",
      DateEntered: normalizeDateFormValue(initialValue.DateEntered),
      EqKey: initialValue.EqKey,
      SchedKey: initialValue.SchedKey,
      fldSRHKey: initialValue.fldSRHKey,
      Department: initialValue.Department,
      fldListType: initialValue.fldListType,
      fldHTML: initialValue.fldHTML,
      deletedAt: initialValue.deletedAt,
      deletedBy: initialValue.deletedBy,
      fldDateTrigger: normalizeDateFormValue(initialValue?.fldDateTrigger),
      fldDeferred: initialValue.fldDeferred,
      fldDeferredDate: normalizeDateFormValue(initialValue?.fldDeferredDate),
      fldHoursCompleted: initialValue.fldHoursCompleted,
      fldHoursTrigger: isNil(initialValue.fldHoursTrigger) || initialValue.fldHoursTrigger === -1 ? null : initialValue.fldHoursTrigger,
      fldIndex: initialValue.fldIndex || null,
      fldIterations: initialValue.fldIterations > 0 ? initialValue.fldIterations : null,
      fldRunOnce: initialValue.fldRunOnce,
      isRecoverable: initialValue.isRecoverable,
      updatedAt: initialValue.updatedAt,
      scheduleType: initialValue.scheduleType,
      simpleCheck: initialValue.simpleCheck || false,
      Equipment: initialValue.Equipment || null,
      fldTimeWarn: initialValue.fldTimeWarn || "",
      RecurPattern: initialValue.RecurPattern ? initialValue.RecurPattern.split(','): null,
      fldHourWarn: initialValue.fldHourWarn || null,
      fldTimeMeasureWarn: initialValue.fldTimeMeasureWarn || null,
      fldHourLock: initialValue.fldHourLock || false,
      fldCSM: initialValue.fldCSM || false,
      fldSMS: initialValue.fldSMS || false,
      fldUserDefined: initialValue.fldUserDefined || null,
      fldIsCheckList: initialValue.fldIsCheckList || false,
      fldLinkID: initialValue.fldLinkID || null,
      fldAssignedTo: isEmpty(initialValue.fldAssignedTo)
        ? []
        : isString(initialValue.fldAssignedTo)
          ? initialValue.fldAssignedTo?.split(', ')
          : initialValue.fldAssignedTo,
      fldHourInterval: initialValue.fldHourInterval || '',
      RecurPatternEveryMonthOrdinal: "",
      RecurPatternEveryMonthDay: "",
      RecurPatternEveryMonth: "",
      RecurPatternEveryYearOrdinal: "",
      RecurPatternEveryYearDay: "",
      RecurPatternEveryYearMonth: "",
      RecurType: initialValue.RecurType,
    },
  });

  const { errors } = useFormState({
    control,
  });
  const { showSnackbar } = useSnackbar();

  const [oldIssuesUndo, setOldIssuesUndo] = useState<TblSchedMaintEq[]>([]);
  const [schedMaintEqs, setSchedMaintEqs] = useState<TblSchedMaintEq[]>([]);
  const [issue, setIssue] = useState<any>(initialValue);
  const [historyCount, setHistoryCount] = useState<number>(0);
  const [workIssueCount, setWorkIssueCount] = useState<number>(0);
  const [eqSchedPickedValue, setEqSchedPickedValue] = useState<string>();
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const forminitialValue = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEqSchedConfirming, setIsEqSchedConfirming] = useState(false);
  const [disableEditDepartment, setDisableEditDepartment] = useState(false);
  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const [triggerTaskCreated, setTriggerTaskCreated] = useState<any>(null);
  const [showTriggerTaskPopup, setShowTriggerTaskPopup] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showLogEntryPopup, setShowLogEntryPopup] = useState<boolean>(false);
  const [showLogEntryPrompt, setShowLogEntryPrompt] = useState<boolean>(false);
  const [logEntry, setLogEntry] = useState<LogEntryDocument>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [showEquipmentsPopup, setShowEquipmentsPopup] = useState<boolean>(false);
  const [linkSchedule, setLinkSchedule] = useState<boolean>(false);
  const [copyWithSuspend, setCopyWithSuspend] = useState<boolean>(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const { TBLSCHEDMAINT } = CHAR_LIMIT;

  const handleEqSchedOptionChange = (event: any) => {
    setEqSchedPickedValue(event.target.value);
  };
  const getDocumentCount = async () => {
    // Count Attachments / Photos / Spares
    const db = await getDatabase();

    // Find and count attachments
    activeSubscriptions.current = [
      db.comments
        .find({
          selector: {
            $and: [
              {referenceIssueId: isNil(initialValue.PKey) ? '' : initialValue.PKey},
              {deletedAt: {$eq: null}},
            ],
          },
        })
        .$.subscribe((c) => {
        setCommentsCount(size(c));
      }),
      // TODO - Remove when refactor the whole component - not using rxdb for Logentries at this point
      // db.logentry.find({
      //   selector: {
      //     MaintKey: initialValue.PKey,
      //   },
      // }).$.subscribe((c: any) => {
      //   setHistoryCount(size(c));
      // }),
    ];

    db.tblschedmaint.$.subscribe(async (ev) => {
      loadSchedMaintEqs();
    });
    db.tblschedmainteq.$.subscribe(async (ev) => {
      loadSchedMaintEqs();
    });
    db.workissues.$.subscribe(async (ev) => {
      loadWorkIssueCount();
    });
  };

  const setInitialValue = async () => {
    let defaultValues = {
      ...getValues(),
    };
    if(getValues('fldHoursTrigger') != initialValue.fldHoursTrigger){
      defaultValues = {
        ...defaultValues,
        fldHoursTrigger: isNil(initialValue.fldHoursTrigger) || initialValue.fldHoursTrigger === -1 ? null : initialValue.fldHoursTrigger,
      }
    }
    forminitialValue.current = defaultValues;

    if (initialValue.populate)
      defaultValues.Equipment = (await initialValue.populate('EqKey'))?.toJSON() || null;

    reset(defaultValues);
  };
  const setLogEntryData = async () => {
    const db = await getDatabase();
    const department = await db.tblddlistdefaults
      .findOne({
        selector: {
          fldListName: 'Department',
          fldMember: user?.Department,
        },
      })
      .exec();
    const document: LogEntryDocument = await db.logentry.newDocument({
      PKey: uuid(),
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      MaintKey: initialValue.PKey,
      Equipment: initialValue.Equipment,
      DayLogType: 2,
      fldIsCheckList: false,
      LogDate: new Date().toISOString(),
      fldPerformedBy: user ? `${user.fldFirst} ${user.fldLast}` : null,
      fldWorkList: initialValue.fldListType,
      Department: department?.fldMember,
      LogEntry: initialValue.fldSubject,
    });

    setLogEntry(document);
  }

  const loadSchedMaintEqs = async () => {
    const db = await getDatabase();
    const response = await db.tblschedmainteq.find({
      selector: {
        deletedBy: null,
      }
    }).exec();
    setSchedMaintEqs([ ...response ]);
  }
  const loadWorkIssueCount = async () => {
    const db = await getDatabase();
    const workissue = await db.workissues.find({
      selector: {
        fldSchedMaintKey: {
          $eq: initialValue.PKey,
        },
        deletedBy:{
          $eq: null,
        },
        Completed:false,
      }
    }).exec();        
    if(workissue)
      setWorkIssueCount(workissue.length)
  }

  useEffect(() => {
    getDocumentCount();
    setInitialValue();
    setLogEntryData();
    loadSchedMaintEqs();
    loadWorkIssueCount();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      forminitialValue.current = {};
    };
  }, [initialValue]);

  const setDeptEditPermission = async () => {
    if (settingsPersonal.fldAllDepts != 2) {
      setDisableEditDepartment(true);
    }
    if(settingsPersonal.fldAllDepts === 1 && user?.Department != initialValue.Department){
      setDisableEdit(true)
    }
  };

  useEffect(() => {
    if (settingsPersonal) {
      setDeptEditPermission();
    }
  }, [settingsPersonal]);

  const onChange = async (name: string, value: any) => {
    if (name === 'Company') {
      setValue(
        'Company',
        { DisplayMember: value?.DisplayMember },
        { shouldDirty: true }
      );
      return;
    }
    // If it's equipment change -> populate location based on it.
    if (name === 'Equipment' && !isNil(value)) {
      const equipment = value as EquipmentDocument;
      setValue('fldLocHierarchy', equipment?.fldLocHierarchy);
      setValue(
        'Equipment',
        { EqKey: value.EqKey, UniqueName: value.UniqueName },
        { shouldDirty: true }
      );
      if (isCreated) initialValue.fldCountHours = equipment.fldCountHours;
      return;
    }

    let shouldDirty = true;

    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    setOldIssuesUndo([]);
    onCancel();
  };

  const extractContent = (s: string) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const isCreation = isCreated;


  const checkIfWorkIssueNeedsToBeUpdated = async (update: boolean = false) => {
    if (!isCreation && (update || formState.dirtyFields.fldDateTrigger || formState.dirtyFields.fldHoursTrigger)) {
      const db = await getDatabase();
      const workissue = await db.workissues.find({
        selector: {
          fldSchedMaintKey: {
            $eq: initialValue.PKey,
          },
          deletedBy: {
            $eq: null
          },
          Completed:false,
        }
      }).exec();
      if (workissue && workissue.length > 0) {
        setIsEqSchedConfirming(true);
      }
    }
  }

  const handleSave = async (data: any, e?: any) => {
    if (!validateForm(data, showSnackbar)) return;
    const db = await getDatabase();

    let {
      fldSubject,
      fldDateTrigger,
      fldDeferredDate,
      fldIterations,
      Equipment,
      fldTimeWarn,
      fldHTML,
      fldHourInterval,
      fldHourLock,
      fldHourWarn,
      fldTimeMeasureWarn,
      fldAssignedTo,
      Department,
      fldListType,
      fldCSM,
      fldSMS,
      RecurType,
      fldUserDefined,
      fldLinkID,
      fldIsCheckList,
      fldHoursTrigger,
      fldHoursCompleted,
      fldRunOnce,
      fldDeferred,
      DateEntered,
      fldIndex,
    } = data;

    if (DateEntered) {
      DateEntered = normalizeDateTime(DateEntered);
    }

    const equipmentDetail = await db.equipment.find({
      selector:{
        EqKey: {$eq: isString(Equipment)? initialValue.EqKey : Equipment?.EqKey},
        deletedAt:{$eq: null}
      }
    }).exec()
    const equipmentFldSRHKey = toJSON(equipmentDetail)[0]?.fldSRHKey;
    const equipmentfldLocHierarchy =  toJSON(equipmentDetail)[0]?.fldLocHierarchy
    const fldCountHours = toJSON(equipmentDetail)[0]?.fldCountHours

    let recurPattern = ''
    //set up recurPattern
    switch (data.RecurType) {
      case "47":{
        recurPattern = data.RecurPatternWeeklyRegenerate?.toString()
        break;
      }
      case "48":{
        const sortedWeekDays = data.weekDays.sort()
        await sortedWeekDays.unshift(`${data.RecurPatternWeeklyEveryDay}`)
        recurPattern = sortedWeekDays.join(',')
        break;
      }
      case "49":{
        recurPattern = data.RecurPatternRegenerate?.toString();
        break;
      }
      case "50":{
        recurPattern = [data.RecurPatternEveryYearOrdinal?.id, data.RecurPatternEveryYearDay?.id, data.RecurPatternEveryYearMonth?.id].join(",");
        break;
      }
      case "51":{
        recurPattern = [data.RecurPatternEveryMonth?.id, data.RecurPatternEveryMonthDay].join(",");
        break;
      }
      case "54":{
        recurPattern = data.RecurPatternRegenerate?.toString();
        break;
      }
      case "55":{
        recurPattern = [data.RecurPatternEveryMonthOrdinal?.id, data.RecurPatternEveryMonthDay?.id, data.RecurPatternEveryMonthMonths].join(",");
        break;
      }
      case "56":{
        recurPattern = [data.RecurPatternEveryDay, data.RecurPatternEveryMonth].join(",");
        break;
      }
      case "62":{
        recurPattern = data.RecurPatternRegenerate?.toString();
        break;
      }
      case "63":{
        recurPattern = "2,3,4,5,6";
        break;
      }
      case "64":{
        recurPattern = data.RecurPatternEvery?.toString();
        break;
      }
      case "100":{
        recurPattern = "";
        break;
      }
      default:{
        recurPattern = isNil(initialValue.RecurPattern)? null : initialValue.RecurPattern
        break;
      }
    }

    const schedMaintDocument = {
      PKey: initialValue.SchedKey,
      fldSubject,
      fldNotes: extractContent(fldHTML) || null,
      fldHoursKey: null,
      fldHourInterval: parseInt(fldHourInterval, 10) || null,
      fldTimeInterval: null,
      fldTimeMeasure: null,
      fldHourWarn:parseInt(fldHourWarn,10)|| null,
      fldTimeWarn: parseInt(fldTimeWarn, 10) || null,
      fldTimeMeasureWarn: fldTimeMeasureWarn?.timeMeasure || null,
      fldSteps: null,
      fldSMS,
      RecurType: parseInt(RecurType, 10) || 0,
      fldAssignedTo:fldAssignedTo?.join(', ') || null,
      Department: isString(Department)
        ? Department
        : Department?.fldMember || null,
      RecurInterval: null,
      RecurPattern: recurPattern,
      fldToolsReqd: null,
      fldHourLock,
      fldTTLWork: null,
      fldCSM,
      fldSchedType: initialValue.fldSchedType ,
      fldUserDefined: isString(fldUserDefined)
        ? fldUserDefined
        : fldUserDefined?.fldMember || null,
      fldListType: isString(fldListType)
        ? fldListType
        : fldListType?.fldMember || null,
      fldDuration : initialValue.fldDuration || null,
      DateEntered: null,
      fldIsCheckList: fldIsCheckList === true ? fldIsCheckList: false,
      fldLinkID,
      fldQuotedPrice : null,
      fldQuotedCurrency: null,
      fldHTML,
      fldRTF: null,
      fldPlainText: extractContent(fldHTML) || null,
      fldSRHKey: equipmentFldSRHKey|| initialValue.fldSRHKey ,
      updatedAt:new Date().toISOString(),
      deletedBy:null
    } as any

    const iterationCount = (isNil(fldIterations) || fldIterations <= 0) ? -1 : fldIterations;

    const schedMaintEQDocument = {
    PKey: initialValue.PKey,
    SchedKey : initialValue.SchedKey,
    EqKey: isString(Equipment)? initialValue.EqKey : Equipment?.EqKey,
    fldHoursTrigger: parseInt(fldHoursTrigger, 10) || null,
    fldHoursCompleted,
    fldDateTrigger: normalizeDateTime(fldDateTrigger),
    fldRunOnce,
    fldIterations: parseInt(iterationCount),
    fldDeferred,
    DateEntered,
    fldIndex,
    fldDeferredDate: normalizeDateTime(fldDeferredDate),
    fldSRHKey: equipmentFldSRHKey|| initialValue.fldSRHKey,
    fldLocHierarchy: equipmentfldLocHierarchy || initialValue.fldLocHierarchy,
    updatedAt:new Date().toISOString(),
    deletedBy:null
    } as any;
    try {
      setOldIssuesUndo([]);
      const resMaint = await db.collections.tblschedmaint.upsert(schedMaintDocument);
      const res = await db.collections.tblschedmainteq.upsert(schedMaintEQDocument);

      const updatedData={
        ...data,
        fldCountHours: fldCountHours, // keep fldCountHours value after saving - is used in ScheduleComponent.
        original: initialValue.original,
        RecurPattern: recurPattern,
        RecurType: parseInt(RecurType, 10),
      } as any
  
      onSave(updatedData, isCreated, e);
      const formVal = getValues();
      reset(formVal);
      showSnackbar({
        type: 'success',
        message: isCreation ? 'New Schedule has been created!' : 'Schedule has been updated!',
      })
      await checkIfWorkIssueNeedsToBeUpdated();
    } catch (e: any) {

      showSnackbar({
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    // onDelete(initialValue);
    setIsDeleting(true);
  };

  const handleDeleteOk = () => {
    onDelete(initialValue.original);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };
  const handleEqSchedDialogOk = async () => {
    const db = await getDatabase();
    const workissue = await db.workissues.find({
      selector: {
        fldSchedMaintKey: {
          $eq: initialValue.PKey,
        },
        deletedBy: {
          $eq: null
        },
        Completed:false,
      }
    }).exec();
    if(workissue && workissue.length > 0) {
      const workIssueItem = workissue[0];
      if(eqSchedPickedValue === 'change') {
        workIssueItem.atomicPatch({
          DateDue: normalizeDateTime(getValues('fldDateTrigger'))
        })
        console.log('workissue updated', workIssueItem);
        showSnackbar({
          message: `Workissue ${workIssueItem.Subject} updated sucessfully`,
          type: 'success'
        });
      } else if(eqSchedPickedValue === 'remove') {
        workIssueItem.atomicPatch({
          Completed: true,
          deletedAt: new Date().toISOString(),
          deletedBy: user?.fldCrewID,
          isRecoverable: false
        });
        showSnackbar({
          message: `Workissue item "${workIssueItem.Subject}" deleted permanently`,
          type: 'success'
        });
      }
    }
    setIsEqSchedConfirming(false);
  };

  const handleEqSchedDialogCancel = () => {
    setIsEqSchedConfirming(false);
  };

  const handleUndo = () => {
    const issue = last(oldIssuesUndo);
    setIssue(issue as TblSchedMaintEq);

    // Remove last step from our store
    setOldIssuesUndo(oldIssuesUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleCancelUndo = () => {
    // setIssue(initialValue);

    if (isCreation) {
      return onCancel();
    }
    reset();
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), showSnackbar)) return;
    if (isEditing) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const activateDeactivateSchedules = async () => {
    const db = await getDatabase();
    const initialDefferedValue = initialValue.fldDeferred;
    try {
      initialValue.fldDeferred ? await activateSchedules([{...initialValue}]): await deActivateSchedules([{...initialValue}]) ;
      initialValue.fldDeferred = !initialDefferedValue;

      showSnackbar({
        type: 'success',
        message: initialDefferedValue ? 'Schedule activated successfully' : 'Schedule deactivated successfully'
      });

      await checkIfWorkIssueNeedsToBeUpdated(true);
    } catch (e: any) {

      showSnackbar({
        type: 'error',
        message: initialDefferedValue ? `Schedule activation error - ${e.message}` : `Schedule deactivation error - ${e.message}`,
      });
    }
  }
  const triggerTask = async () => {
    handleClose();
    const db = await getDatabase();
    if(initialValue.fldDeferred){
      setTriggerTaskCreated(true);
      setShowTriggerTaskPopup(true);
      return;
    }
    const workissue = await db.workissues.find({
      selector: {
        fldSchedMaintKey: {
          $eq: initialValue.PKey,
        },
        deletedBy:{
          $eq: null,
        },
        Completed:false,
      }
    }).exec();
    
    if(workissue && workissue.length > 0) {
      setTriggerTaskCreated(true);
      setShowTriggerTaskPopup(true);
      return;
    }

    let equipment: any = await db.equipment.find({
      selector: {
        EqKey: initialValue.EqKey,
      }
    }).exec();
    if(equipment && equipment.length > 0) equipment = equipment[0];
    const document = {
      JobNumber: uuid(),
      Subject: initialValue.fldSubject,
      EqKey: initialValue.EqKey,
      fldSchedMaintKey: initialValue.PKey,
      fldSMSType: "PM",
      fldSMSID: null,
      Equipment: equipment.UniqueName,
      fldDeptCreator: user?.Department,
      Department: initialValue.Department,
      DateStart: normalizeDateTime(initialValue.fldDateTrigger),
      DateDue: initialValue?.fldHoursTrigger ? null: normalizeDateTime(initialValue.fldDateTrigger),
      AssignedTo: isArray(initialValue.fldAssignedTo) ? initialValue.fldAssignedTo?.join(','): initialValue.fldAssignedTo,
      fldSMS: initialValue.fldSMS || equipment?.fldSMS,
      fldLocHierarchy: equipment?.fldLocHierarchy,
      fldIsCheckList: false,
      fldHTML: initialValue.fldHTML,
      fldSRHKey: initialValue.fldSRHKey,
      updatedAt: new Date().toISOString(),
      fldIsWarranty: false,
      fldWorkList: initialValue.fldListType,
      fldIsCAR: false,
    } as any;
    try {
      await db.workissues.upsert(document);
      copySpareUsedFromWorkissue(initialValue.SchedKey, document);

      setTriggerTaskCreated(false);
      setShowTriggerTaskPopup(true);
    }
    catch (e: any) {
      showSnackbar({
        message: e.message,
        type: 'error'
      });
    }
  }

  const addLogEntry = () => {
    handleClose();
    setShowLogEntryPrompt(true);
  }

  const openLogEntryPopup = () => {
    setShowLogEntryPrompt(false);
    setShowLogEntryPopup(true);
  }

  const handleCancelLogEntry = () => {
    setShowLogEntryPopup(false);
    setProcessing(false);
  }

  const handleSaveLogEntry = async (logEntry: LogEntry) => {
    const db = await getDatabase();
    const maintEq = await db.tblschedmainteq
      .findOne({
        selector: {
          PKey: initialValue.PKey,
        },
      })
      .exec();

    if (!isNil(maintEq)) {
      // MaintEq shouldn't null since we have preSave check.
      const recurrence = await getRecurrencePerSchedMaint(maintEq, logEntry as LogEntryDocument);
      if (recurrence && !isNil(maintEq?.EqKey) && !isNil(logEntry.fldHours)) {
        await equipmentHoursUpdate(maintEq?.EqKey, logEntry.fldHours);
      }
      
      // advance the date trigger
      let nextDueDate: Date | undefined;
      if(initialValue.scheduleType !== "Hours Only") {
        nextDueDate = getRecurrenceDate(initialValue.RecurType, initialValue.RecurPattern, 
          moment(logEntry.LogDate).toDate(), maintEq.fldDateTrigger);
      } else {
        nextDueDate = undefined; // TD-1711 if hours only schedule set next due date to null.
      }

      await maintEq?.atomicPatch({
        ...recurrence,
        fldDateTrigger: normalizeDateTime(nextDueDate),
      });

      copySpareUsedFromLogEntry(initialValue.SchedKey, logEntry as LogEntryDocument);

      const updatedData={
        ...initialValue,
        original: initialValue.original,
        ...recurrence,
        fldDateTrigger: normalizeDateTime(nextDueDate),
      } as any;
      onSave(updatedData, isCreated);
    }

    handleCancelLogEntry();
    showSnackbar({
      message: 'New log entry has been created!',
      type: 'success',
    });
  }

  const copyItem = () => {
    handleClose();
  setLinkSchedule(false);
    setShowEquipmentsPopup(true);
  }

  const onCloseCopy = (created?: boolean) => {
    setShowEquipmentsPopup(false);
    showSnackbar({
      type: 'success',
      message: created ? 'Changes saved' : 'No changes made',
    });
  }

  const linkItem = () => {
    handleClose();
    setLinkSchedule(true);
    setShowEquipmentsPopup(true);
  }

  const unlinkItem = async () => {
    const db = await getDatabase();
    try {
      const schedMaintRes: any = await db.tblschedmaint.findOne({
        selector: {
          PKey: initialValue.SchedKey,
        },
      }).exec();
      let schedMaint: any = JSON.parse(JSON.stringify(schedMaintRes));
      delete schedMaint.updatedAt;
      delete schedMaint.deletedBy;
      delete schedMaint.deletedAt;

      const linkedSchedMaintEqs: any[] = await db.tblschedmainteq.find({
        selector: {
          SchedKey: initialValue.SchedKey,
          EqKey: initialValue.EqKey,
        },
      }).exec();
      for (let i in linkedSchedMaintEqs) {
        // if(i !== "0") {
          const linkedSchedMaintEq: any = JSON.parse(JSON.stringify(linkedSchedMaintEqs[i]));
          delete linkedSchedMaintEq.autoId;
          const newSchedMaint: any = {
            ...schedMaint,
            PKey: uuid(),
          };
          delete newSchedMaint.autoId;
          await db.tblschedmaint.upsert(newSchedMaint);

          linkedSchedMaintEq.SchedKey = newSchedMaint.PKey;
          await db.collections.tblschedmainteq.upsert(linkedSchedMaintEq);
        // }
      }
      showSnackbar({
        type: 'success',
        message: 'Successfully unliked',
      });
    }
    catch (e: any) {
      console.error(e);
      showSnackbar({
        type: 'error',
        message: e.message,
      });
    }
    handleClose();
  }

  const actions = [
    {
      icon: <img src={IconTask} alt="" />,
      name: 'Trigger Task',
      onclick: triggerTask,
      disabled: workIssueCount > 0
    },
    {
      icon: <RestartAlt sx={{ color: "#002041" }} />,
      name: 'Log and reset now',
      onclick: addLogEntry,
      disabled: workIssueCount > 0
    },
    {
      icon: <FileCopy sx={{ color: "#002041" }} />,
      name: 'Copy',
      onclick: copyItem,
    },
    {
      icon: <Link sx={{ color: "#002041" }} />,
      name: 'Link',
      onclick: linkItem,
    }
  ];

  let schedMaintEqList: any = schedMaintEqs.filter((schedMaintEq: any) => schedMaintEq.SchedKey === initialValue.SchedKey);
  if(schedMaintEqList.length > 1) {
    actions.push({
      icon: <LinkOff sx={{ color: "#002041" }} />,
      name: 'Unlink',
      onclick: unlinkItem,
    });
  }
  actions.push({
    icon: <IconButton><ScheduleIcon color={ initialValue.fldDeferred  ? 'success': 'error'}/></IconButton>,
    name: initialValue.fldDeferred ? 'Activate Schedule': 'Suspend Schedule',
    onclick: activateDeactivateSchedules,
  });


  if (isNil(issue)) return null;

  const hasValuesBeenChanged =
    formState.isDirty &&
    (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreation;
  
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const classes = useStyles();

  const relatedKeys = filterNonNullStrings([initialValue.PKey]);

  const formClass = type === 'Dialog'
  ? 'relative bg-white flex-grow'
  : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <>
      <form
        id="EquipmentSchedulesDetailForm"
        className={`${formClass}`}
        onSubmit={handleSubmit(handleSave)}
      >
        <div className="bg-white h-full flex-grow pt-6">
          <div className="px-6 h-full flex flex-col">
            <div className="mb-6">
              {(disableEdit|| editFlag) && <RecordEditWarningCard />}
              <div className="mui-textfield-header mb-2">
                <Input
                  inputProps={{
                    size: 'medium',
                    label: 'Task Subject',
                    variant: 'standard',
                  }}
                  rules={{ required: true, maxLength: TBLSCHEDMAINT.fldSubject }}
                  warning={(value) => handleCharLimitWarning(value, TBLSCHEDMAINT.fldSubject)}
                  control={control}
                  name="fldSubject"
                />
              </div>
              {!disableEdit && (
                <div className="mb-2">
                  <ProcedureDocument
                    initialValue={initialValue}
                    tableName='TblSchedMaint'
                    setSnackbar={showSnackbar}
                    onSave={(data: any) => onSave(data, false, undefined)}
                  />
                </div>
              )}
            </div>

            <div className="mt-3 mb-20">
              {
                <Tabs
                  tabs={[
                    {
                      label: 'Summary',
                      component: (
                        <EqMaintSummaryForm
                          watch={watch}
                          control={control}
                          initialValue={initialValue}
                          form={issue}
                          onChange={onChange}
                          setValue={setValue}
                          getValues={getValues}
                          disableEdit={disableEdit}
                        />
                      ),
                    },
                    {
                      // label: `History (${historyCount})`, // TODO - temporary hide the count until refactoring Equipment mudule with the .net api
                      label: `History`,
                      component: (
                        <HistoryTab 
                          keyName='maintKey' 
                          keyValue={initialValue.PKey}
                          setHistoryCount={setHistoryCount}
                          fldCountHours={initialValue.fldCountHours}
                          disableEdit={disableEdit} 
                        />
                      ),
                    },
                    {
                      label: <AttachmentTabButton key="attachments" type="attachments" selector={{ fldRecordKey: isNil(initialValue.PKey) ? '' : initialValue.PKey, }} />,
                      component: (
                        <AttachmentTabOld
                          type="attachments"
                          primaryKey={initialValue.PKey}
                          relatedKeys={relatedKeys}
                          SRHKey={initialValue.fldSRHKey}
                          allowedFileExtensions={['.pdf']}
                          tableName="TblSchedMaintEq"
                          disabled={editFlag}
                          disableEdit={disableEdit}
                        />
                      ),
                    },
                    {
                      label: <AttachmentTabButton key="photos" label="Photos" type="photos" selector={{ fldRecordKey: isNil(initialValue.PKey) ? '' : initialValue.PKey, }} />,
                      component: (
                        <AttachmentTabOld
                          type="photo"
                          primaryKey={initialValue.PKey}
                          relatedKeys={relatedKeys}
                          SRHKey={initialValue.fldSRHKey}
                          allowedFileExtensions={[
                            '.jpg',
                            '.jpeg',
                            '.gif',
                            '.png',
                          ]}
                          tableName="TblSchedMaintEq"
                          disabled={editFlag}
                          disableEdit={disableEdit}
                        />
                      ),
                    },
                    {
                      label: `Comments (${commentsCount})`,
                      component: (
                        <CommentsOld
                          selectorType="TblSchedMaintEq"
                          selectorKeyValue={initialValue.PKey}
                          disableEdit={disableEdit}
                        />
                      ),
                    },
                  ]}
                />
              }
            </div>
          </div>
        </div>
        {type !== 'Dialog' && (
          <StickyAppBar
            cancelText="Cancel"
            okType={isEditing ? 'submit' : 'button'}
            okText={isEditing ? 'Save' : 'Close'}
            onOk={() => handleOk(isEditing)}
            onCancel={isEditing ? () => handleCancelUndo() : undefined}
            disabled={(editFlag || disableEdit)  && isEditing}
          >
            {!(editFlag || disableEdit)  &&
            <Box sx={{ position: 'relative', height: 70 }}>
                <Backdrop open={open} />
                <SpeedDial
                    ariaLabel="SpeedDial tooltip example"
                    sx={{ position: 'absolute', bottom: 12, right: 12 }}
                    FabProps={{ size: 'small' }}
                    icon={
                      <SpeedDialIcon
                        sx={{ fontSize: 'small' }}
                        icon={<Add />}
                        openIcon={<Close />}
                      />
                    }
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      classes={classes}
                      onClick={() => {if(!action.disabled) action.onclick()}}
                      FabProps= {{disabled: action.disabled}}
                    />
                  ))}
                </SpeedDial>
            </Box>
            }
            {!isEmpty(oldIssuesUndo) && (
              <IconButton
                onClick={handleUndo}
                color="inherit"
                aria-label="Undo last step"
              >
                <Undo />
              </IconButton>
            )}
            {!editFlag && !isNil(issue.PKey) && !disableEdit && (
              <IconButton
                onClick={handleDelete}
                color="error"
                aria-label="Delete task"
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </StickyAppBar>
        )}
      </form>


      <Dialog open={showTriggerTaskPopup} onClose={() => setShowTriggerTaskPopup(false)}>
          <DialogContent dividers>
            <Typography>
              {triggerTaskCreated
                ? "No task was created for this schedule item. Please ensure the item is not currently outstanding on your work list or schedule is not suspended."
                : "A task was successfully created for this schedule item."
              }
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              autoFocus
              onClick={() => setShowTriggerTaskPopup(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {logEntry &&
          <CustomDialog 
            scroll="paper" 
            fullWidth 
            maxWidth="md" 
            open={showLogEntryPopup}
          >
            <DialogTitle>
              <span className="font-bold text-2xl">Log and Reset Schedule</span>
            </DialogTitle>

            <DialogContent dividers sx={{ p: 0 }}>
              <LogEntryDetailFormOld
                initialValue={logEntry}
                onCancel={handleCancelLogEntry}
                onSave={handleSaveLogEntry}
                onDelete={() => { }}
                editFlag={false}
                isCreated={true}
                isLogAndReset={true}
                type='Dialog'
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'flex-end' }}>
              <LoadingButton
                loading={processing}
                type="submit"
                form="logentry-details-form"
                variant="contained"
              >
                Save
              </LoadingButton>

              <Button
                autoFocus
                onClick={() => setShowLogEntryPopup(false)}
              >
                Close
              </Button>
            </DialogActions>
          </CustomDialog>
        }

        <Dialog open={showLogEntryPrompt} onClose={() => setShowLogEntryPrompt(false)}>
          <DialogContent dividers>
            Are you sure you wish to log and reset this PM?
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={openLogEntryPopup}>
              Yes
            </Button>
            <Button
              autoFocus
              onClick={() => setShowLogEntryPrompt(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {showEquipmentsPopup &&
        <SnackbarCopyComponent
            initialValue={initialValue}
            onClose={onCloseCopy}
            linkSched={linkSchedule}
          />
        }

        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content={scheduleDeleteWarningMessage}
          okText="Yes"
          color="error"
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
        <EqSchedPMConfDialog
          visible={isEqSchedConfirming}
          title="Update Work List"
          onChange={handleEqSchedOptionChange}
          okText="Apply"
          color="error"
          onOk={handleEqSchedDialogOk}
          onCancel={handleEqSchedDialogCancel}
        />
    </>
  )
};

export default EquipmentSchedulesDetailForm;
