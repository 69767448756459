import { FC, useEffect, useState } from 'react';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { isNil, isArray, last, uniqBy, concat, isNull } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormGroup, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import { Control, UseFormWatch } from 'react-hook-form';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import Input from 'src/components/UI/Forms/Input';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import { getDatabase } from '../../../../rxdb';
import { WorkIssues } from '../../../../generated/graphql';
import EquipmentDropdownOld from '../../../Dropdowns/EquipmentDropdown/indexOld';
import CategoryDropdownOld from '../../../Dropdowns/CategoryDropdown/indexOld';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import { isNotNil, toJSON } from '../../../../utils';
import LocationDropdownOld from '../../../Dropdowns/LocationDropdown/indexOld';
import { useAppState } from 'src/contexts/app-state';
import MultiSelect from 'src/components/UI/Forms/MultiSelect';
import { isValidDateFormat } from 'src/utils/format-dates';
import { Subscription } from 'rxjs';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';

interface TaskItemSummaryProps {
  form: WorkIssues;
  initialValue: WorkIssuesDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  disableEdit? : boolean;
  isCreated?: boolean;
}

const TaskSummaryForm: FC<TaskItemSummaryProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  getValues,
  disableEdit = false,
  isCreated = false,
}) => {
  const { settingsPersonal } = useAppState();
  const [priorities, setPriorities] = useState<TblDdListDefaultsDocument[]>([]);
  const [statuses, setStatuses] = useState<TblDdListDefaultsDocument[]>([]);
  const [workList, setWorkList] = useState<TblDdListDefaultsDocument[]>([]);
  const [departments, setDepartments] = useState<TblDdListDefaultsDocument[]>([]);
  const [assignments, setAssignments] = useState<any[]>([]);
  const [currentEqHours, setCurrentEqHours] = useState<number | null>(null);
  const [schedHoursNextDue, setSchedHoursNextDue] = useState<number | null>(null);
  let subscriptions: Subscription[] = [];

  const init = async () => {
    const db = await getDatabase();
    const defaultsSubscription = db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } }}).$.subscribe((defaults) => {
      const priorities = defaults.filter((d) => d.fldListName === 'Priority' && d.fldMember !== "");
      const statuses = defaults.filter((d) => d.fldListName === 'Status' && d.fldMember !== "");
      const workList = defaults.filter((d) => d.fldListName === 'Work List Names' && d.fldMember !== "");
      const departments = defaults.filter((d) => d.fldListName === 'Department' && d.fldMember !== "");
      setPriorities(toJSON(priorities));
      setStatuses(toJSON(statuses));
      setWorkList(toJSON(workList));
      setDepartments(toJSON(departments));
    });

    const crewSubscription = db.crew.find({
      selector: { 
        fldPosition: { $ne: null },// Select members only with Position set.
        fldIsGuest: {$eq: false},// remove guest from list
        deletedBy: {$eq: null},// Deleted filter
        DateFire: {$eq: null},// released crew filter
     }
    }).$.subscribe((crewItems: any) => {
      const assignmentPositions = uniqBy<any>(crewItems, (a) => a.fldPosition).map((a) => a.fldPosition);
      const assignmentNames = uniqBy<any>(crewItems, (a) => a.fldFirst + ' ' + a.fldLast).map((a) => a.fldFirst + ' ' + a.fldLast);
      const deptNames = uniqBy<any>(departments, (a) => a.fldMember).map((a) => a.fldMember);;
      setAssignments(concat(deptNames, assignmentPositions, assignmentNames).map((item: any) => ({ name: `${item}` })))
    })
    
    if (initialValue?.EqKey != null) {
      db.equipment.findOne({ selector: {EqKey: initialValue.EqKey} })
        .$.subscribe((equipment) => {
            if (equipment && equipment.fldCountHours) { 
                setCurrentEqHours(equipment.Hours ?? null);
            } else {
                setCurrentEqHours(null);
            }
        });
    }

    if (initialValue?.fldSchedMaintKey != null) {
      db.tblschedmainteq.findOne({ selector: {PKey: initialValue.fldSchedMaintKey} })
          .$.subscribe((schedMaintEq) => {
              if (schedMaintEq && (schedMaintEq.fldHoursTrigger !== null && schedMaintEq.fldHoursTrigger !== -1)) {
                  setSchedHoursNextDue(schedMaintEq.fldHoursTrigger ?? null);
              } else {
                setSchedHoursNextDue(null);
              }
          });
    }

    subscriptions = [defaultsSubscription, crewSubscription];
  };

  useEffect(() => {
    if (isNil(initialValue)) return;
    let isMounted = true
    init();
    // Cleanup function
    return () => {
      isMounted = false; // Set the mounted flag to false when component unmounts
      // Unsubscribe from all subscriptions
      subscriptions.forEach(subscription => subscription.unsubscribe());
    };
  }, [initialValue]);

  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
  ) => {
    const sortedData = items?.sort((a: any, b: any) => a.fldMember.localeCompare(b.fldMember));
    return (
      <Autocomplete
        defaultPayload={{
          fldListName,
          fldT4Reqd: true,
          fldIndex: null,
        }}
        displayExpr="fldMember"
        keyExpr="PKey"
        onChange={onChange}
        freeSolo={freeSolo}
        options={sortedData}
        label={fldListName}
        control={control}
        name={fieldName}
        disabled={fldListName === "Department" ? disableEdit: false}
        collection="tblddlistdefaults" // Use this in order to create new items.
      />
    );
  };

  const category = watch('fldSRHKey', null);

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="my-4">
            <RichTextEditor
              control={control}
              name="fldHTML"
              onChange={onChange}
            />
          </div>

          {/* Category Hierarchial, collapsable Tree */}
          {/* no need validator in taskDE form */}
          <div className="mt-3">
            <CategoryDropdownOld
              name="fldSRHKey"
              label="Category"
              defaultValue={initialValue.fldSRHKey}
              control={control}
              onChange={onChange}
              rules={{
                required: true,
              }}
              sourceForm="task"
              allDepts={settingsPersonal?.fldAllDepts > 0}
              disabled={!isCreated && !isNull(initialValue?.fldSchedMaintKey)}
            />
          </div>

          <div className="mt-3">
            <EquipmentDropdownOld
              label="Equipment"
              control={control}
              defaultValue={initialValue.EqKey}
              category={(isArray(category) ? last(category) : category) || null}
              onChange={onChange}
              disabled={disableEdit || (!isCreated && !isNull(initialValue?.fldSchedMaintKey))}
            />
          </div>

          <div className="mt-3">
            <LocationDropdownOld
              control={control}
              label="Location"
              name="fldLocHierarchy"
              onChange={onChange}
              sourceForm="task"
              defaultValue={initialValue.fldLocHierarchy}
              allDepts={settingsPersonal?.fldAllDepts > 0}
              disabled={!isCreated && !isNull(initialValue?.fldSchedMaintKey)}
            />
          </div>

          <div className="mt-3">
            <FormGroup row>
              <Checkbox
                control={control}
                name="fldIsWarranty"
                label="Warranty Item"
              />
              <Checkbox
                control={control}
                name="fldSMS"
                label="Critical Task (SMS Required)"
              />
            </FormGroup>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="planning-status"
          id="planning-status"
        >
          Planning & Status
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <div className="w-full flex flex-col">
            { isNotNil(initialValue?.EqKey) && isNotNil(currentEqHours) && (
                <Grid className='text-sm mb-4' container spacing={2} columns={16}>
                  <Grid item xs={4.4}>
                    <span><b>Current Equipment Hours:</b>{` ${currentEqHours}`}</span>
                  </Grid>
                  { isNotNil(schedHoursNextDue) && (
                    <Grid item xs={8}>
                      <span><b>Scheduled Hours Next Due:</b>{` ${schedHoursNextDue}`}</span>
                    </Grid>
                  )}
                </Grid>
              )}
              <FormGroup row>
                <DatePicker
                  name="DateStart"
                  control={control}
                  label="Start Date"
                  rules={{
                    validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                  }}
                />

                <Box className="h-px w-2 self-center mx-2 bg-dark-blue" />

                <DatePicker
                  name="DateDue"
                  control={control}
                  label="Due Date"
                  minDate={getValues().DateStart}
                  rules={{
                    validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                  }}
                />
                <div className="ml-5 flex-grow">
                  {renderListDefaultBox(priorities, 'fldPriority', 'Priority', true)}
                </div>
              </FormGroup>

              <FormGroup row className="mt-4">
                <div className="flex-grow">
                  {renderListDefaultBox(statuses, 'fldStatus', 'Status', true)}
                </div>

                <div className="ml-5 w-40">
                  <Input
                    name="PercentComplete"
                    inputProps={{
                      label: '% Complete',
                      type: 'number',
                      inputProps: { min: 0, max: 100, style: { textAlign: 'end' } },
                      style: { textAlign: 'end' },
                    }}
                    control={control}
                    rules={{
                      min: 0,
                      max: 100,
                    }}
                  />
                </div>
              </FormGroup>

              <div className="mt-4">
                {renderListDefaultBox(
                  workList,
                  'fldWorkList',
                  'Work List Names',
                  true,
                )}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="assignment"
          id="assignment"
        >
          Assignment & Responsibility
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <div>
              <MultiSelect
                control={control}
                name='AssignedTo'
                keyExpr='AssignedTo'
                displayExpr='name'
                options={assignments}
                label='Assign to...'
                onChange={onChange}
              />
            </div>

            <div className="mt-4">
              {renderListDefaultBox(departments, 'Department', 'Department', true)}
            </div>

            <div className="mt-4">
              <CompaniesDropdownOld
                control={control}
                label="Company"
                name="Company"
                onChange={onChange}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TaskSummaryForm;
