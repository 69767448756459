import * as React from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, Alert, Fab, Snackbar, Tooltip, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close as CloseIcon, RestoreTwoTone as RestoreTwoToneIcon } from '@mui/icons-material';
import { FC, useEffect, useRef, useState, Suspense } from "react";
import { useForm } from "react-hook-form";
import { get, isArray, isDate, isEmpty, isNil, omitBy } from 'lodash';
import { v4 as uuid } from "uuid";
import { Subscription } from 'rxjs';
import { equipmentHoursUpdate } from 'src/utils';
import WarningDialog from 'src/components/UI/WarningDialog';
import { AMCS_RL_URL } from 'src/consts';
import { AMCSBadge } from 'src/components/UI/AMCSBadge';
import { getDatabase } from 'src/rxdb';
import { TblRlDataDocument } from 'src/rxdb/collections/RLData/schema';
import { convertDateNumberToDateString, convertDateToTimeNumber, convertTimeNumberToDate } from 'src/utils/format-dates';
import { useAuth } from 'src/contexts/auth';
import { TblRunningLogDocument } from 'src/rxdb/collections/RunningLog/schema';
// import RunningLogCreateForm from './RunningLogCreateForm';
// import Comments from 'src/modules/Comments';
const RunningLogCreateForm = React.lazy(() => import("./RunningLogCreateForm"));
const Comments = React.lazy(() => import("../../../modules/Comments/indexOld"));

const Transition = React.forwardRef<any, any>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface InjectedProps {
  isCreating?: boolean;
  RLData?: TblRlDataDocument;
  runningLog?: TblRunningLogDocument;
  visible: boolean;
  onSave: (data: TblRlDataDocument, isCreated: boolean) => void;
  onClose: () => void;
}

const CreateRunningLogDialog: FC<InjectedProps> = ({ isCreating, visible, RLData, runningLog, onClose, onSave }) => {
  const [commentsVisible, setCommentsVisible] = useState<boolean>(false);
  const commentSubscription = useRef<Subscription>();
  const [commentCount, setCommentCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [fatchingFail, setFatchingFail] = useState(false);
  const [amcsButtonDisplay, setAmcsButtonDisplay] = useState(false);
  const { user } = useAuth();

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  let convertedTakenAt: Date = new Date();
  if (RLData && RLData.fldYMSTakenAt !== undefined && RLData.fldYMSTakenAt !== null) {
    convertedTakenAt = convertTimeNumberToDate(RLData.fldYMSTakenAt);
  }

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm<any>({
    defaultValues: {
      ...JSON.parse(get(RLData, ['readings'], '{}') || '{}'),
      fldYMSTakenAt: convertedTakenAt,
    },
  });

  const setAMCButtonPermission = async () => {
    const db = await getDatabase();
    const buttonShow = await db.tblsettingsapplication.findOne({
      selector: {
        fldPref: "MONSYS"
      }
    }).exec();
    if (buttonShow) {
      setAmcsButtonDisplay(true);
    }
  };

  useEffect(() => {
    setAMCButtonPermission();
  }, [visible]);

  useEffect(() => {
    if (isNil(RLData)) return;

    reset({
      ...getValues,
      ...JSON.parse(get(RLData, ['readings'], '{}') || '{}'),
      fldYMSTakenAt: convertedTakenAt,
    });
  }, [visible]);

  const [processing, setProcessing] = useState<boolean>(false);

  const onItemSelect = (issue: TblRlDataDocument) => {
    console.log(issue);
  };

  const handleSave = async (data: { [key: string]: any }) => {
    if (isNil(RLData)) return;
    const db = await getDatabase();
    const initialRLData = typeof RLData.toJSON === 'function' ? RLData.toJSON() : RLData;
    const document = {
      ...initialRLData,
      readings: JSON.stringify(omitBy(data, d => {
        if (isArray(d)) return d.some(a => isEmpty(a));
        if (isDate(d)) {
          return false; // Don't omit Date objects - is the reading time of type 'Time'.
        }
        if (d === true) {
          return false; // Don't omit 'true' - is the reading value of type 'Check'.
        }
        return isEmpty(d) || isNil(d);
      })),
      fldCrewId: user?.fldCrewID,
      FKey: RLData.FKey,
      updatedAt: new Date().toISOString(),
      fldYMSTakenAt: convertDateToTimeNumber(data.fldYMSTakenAt),
      PKey: RLData?.PKey || uuid(), // Set primary key, so we will be able to upsert.
    } as any;
    try {
      setProcessing(true);

      const res = await db.tblrldata.upsert(document);

      const eqHourPromise = Object.keys(data).map(async (key) => {
        const metaGrpItem = await db.collections.tblrlmetagrpsneq.findOne({ selector: { PKey: key } }).exec();
        if (metaGrpItem?.fldRdgCaption === 'HOURS') {
          await equipmentHoursUpdate(metaGrpItem.EqKey, Number(data[key]));
        }
      });
      await Promise.all(eqHourPromise);
      
      reset();
      onSave(res, isNil(RLData?.primary));
      setProcessing(false);
    } catch (e) {
      console.error(e);
      setProcessing(false);
    }
  };

  const handleCommentsClick = () => {
    setCommentsVisible(!commentsVisible);
  };

  const handleCommentsClose = () => {
    setCommentsVisible(false)
  }

  const getComments = async () => {
    const db = await getDatabase();

    commentSubscription.current = db.comments.find({
      selector: {
        referenceIssueId: runningLog?.PKey,
        referenceIssueType: 'tblRunningLog',
        deletedAt: { $eq: null },
      }
    })
      .$.subscribe((comments) => {
        const updateComments = comments.filter(item => !item.replyId);
        setCommentCount(updateComments?.length || 0);
      });
  };

  useEffect(() => {
    if (!!runningLog?.PKey) {
      getComments();
    }
  }, [runningLog?.PKey]);

  const OnFatchCancleClick = () => {
    setFatchingFail(false);
  };

  const handleAMCSClick = async () => {
    setIsFetching(true);
    const db = await getDatabase();
    const objectData = await db.tblrlmetagrpsneq.find().exec();
    const enums = await db.tblrlenums
      .find({
        selector: {},
      })
      .exec();
    try {
      let body;
      try {
        const response = await fetch(AMCS_RL_URL);
        body = await response.json();
      } catch (e) {
        setFatchingFail(true);
      }

      if (body.errors || isEmpty(body)) {
        setFatchingFail(true);
        return;
      }
      for (const reading of body) {
        const matchingObject = objectData.find(obj => obj.fldMonSysTag === reading.tag);

        if (matchingObject) {
          const matchingEnum = enums.find(enumItem => enumItem.fldDataTypeNumber === parseInt(matchingObject.fldDataType || ''));
          if (matchingEnum && (reading.value === "1" || reading.value === "0")) {
            if (matchingEnum.ValueList) {
              const values = matchingEnum.ValueList.split(',');
              let selectedValue;
              if (reading.value === "1") {
                selectedValue = values[0];
              } else if (reading.value === "0") {
                selectedValue = values[1];
              }
              setValue(matchingObject.PKey, selectedValue);
            }
          } else {
            setValue(matchingObject.PKey, reading.value);
          }

        }
        setSnackbar({
          open: true,
          message: 'Reading updated!',
          type: 'success',
        });
      }
    } catch (e) {
      console.log('Error handleAMCSClick():', e)
    }
    setIsFetching(false);
  };

  return (
    <Dialog
      fullScreen
      open={visible}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            disabled={processing}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`${convertDateNumberToDateString(runningLog?.fldYMSLogDate || 0)}`}
          </Typography>
          <div>
          {amcsButtonDisplay &&
              <Tooltip title='Poll AMCS'>
                <IconButton
                  onClick={handleAMCSClick}
                  className="pr-8"
                  disabled={isFetching}
                >
                  <AMCSBadge color="secondary" badgeContent="AMCS">
                    <RestoreTwoToneIcon sx={{ color: 'white' }} />
                  </AMCSBadge>
                </IconButton>
              </Tooltip>
            }
            <LoadingButton
              autoFocus
              type="submit"
              color="inherit"
              loading={processing}
              form="running-log-form"
            >
              Save
            </LoadingButton>
          </div>
        </Toolbar>
      </AppBar>
      <WarningDialog
        visible={fatchingFail}
        title="Server error"
        content="Something went wrong. Check your connection and try again. If the problem persists, please contact support"
        okText="Yes"
        color="error"
        onCancel={OnFatchCancleClick}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={4000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <DialogContent>
        <form
          id="running-log-form"
          onSubmit={handleSubmit(handleSave)}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <RunningLogCreateForm 
              isCreation={isCreating} 
              isFetchingAMCS={isFetching}
              runningLog={runningLog} 
              control={control} 
              darken 
              onSelect={onItemSelect} 
            />
          </Suspense>
        </form>
      </DialogContent>

      <Dialog 
        maxWidth="lg" 
        fullWidth 
        scroll="paper" 
        open={commentsVisible} 
        onClose={handleCommentsClose}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCommentsClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 14,
              color: (theme) => theme.palette.grey[400],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Suspense fallback={<div>Loading comments...</div>}>
            <Comments
              selectorType="tblRunningLog"
              selectorKeyValue={runningLog?.PKey || ""}
              addCommentOnly={true}
            />
          </Suspense>
        </DialogContent>
      </Dialog>

      <Fab
        onClick={handleCommentsClick}
        sx={{ minHeight: 48, width: 140, marginBottom: '25px', marginRight: '25px', alignSelf: 'flex-end' }}
        color="primary"
        variant="extended"
      >
        Comments ({commentCount})
      </Fab>
    </Dialog>
  );
};

export default CreateRunningLogDialog;
